import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/auth';
import { MenuService } from 'src/app/services/menu/menu-service';
import { OrderService } from 'src/app/services/pedidos/pedidos.service';
import { RejectReason } from 'src/app/shared/models/RejectReason';
import { Order } from 'src/app/shared/models/orders';
import { ReturnReason } from 'src/app/shared/models/returnReason';

@Component({
  selector: 'app-pedido-entregados',
  templateUrl: './pedido-entregados.component.html',
  styleUrls: ['./pedido-entregados.component.css'],
})
export class PedidoEntregadosComponent implements OnInit {
  TextoInfo: string = 'Ver más información';
  fechaMinima: Date;
  fechaMaxima: Date;
  obraSeleciona: string = '';
  codigobraSeleciona: string = '';
  orders: Order[] = [];
  ordersOriginal: Order[] = [];
  numberOrdersDelivered: number = 0;
  numberOrdersRejected: number = 0;
  numberOrdersPending: number = 0;
  rejectReasons: RejectReason[] = [];
  dialogRef: MatDialogRef<any>;
  titleModalUpdate:string;
  isConfirUpdate=false;

  ordersSelected: Order;
  constructor(
    private menu: MenuService,
    private dialog: MatDialog,
    private readonly orderService: OrderService,
    private router: Router,
    private alertService: AlertService,
  ) {
    menu.btnPrevius = true;
    menu.tilteMenu = 'Historial de pedidos';
  }

  isAccordionOpen = false;
  returnReasons: ReturnReason[] = [];

  @ViewChild('modalModificar') modalModificar!: TemplateRef<any>;

  toggleAccordion() {
    this.isAccordionOpen = !this.isAccordionOpen;
    if (this.isAccordionOpen) {
      this.TextoInfo = 'Ver menos información';
    } else {
      this.TextoInfo = 'Ver más información';
    }
  }

  ngOnInit() {
    this.getReturnReasons();
    this.orderService.getRejectReasons().subscribe((data) => {
      this.rejectReasons = data;
    });

    this.getOrders();
    
  }

  getOrders(){
    this.orderService.getAllOrders().subscribe((data) => {
      console.log(data);
      debugger;
      this.ordersOriginal = data;
      this.ordersOriginal = this.ordersOriginal.filter(
        (order) =>
          order.status >= 6 ||
          order.status == 0 ||
          order.rejectionFlag != '' ||
          order.isNotSigned ||
          order.isSignature ||
          !moment(order.orderDate, 'DD/MM/YYYY').isAfter(
            moment().subtract(1, 'days')
          )
      );
      this.orders = this.ordersOriginal.filter(x=>x.flag_pending !="1");
      this.numberOrdersDelivered = this.ordersOriginal.filter((order) =>
        this.isDelivered(order)
      ).length;
      this.numberOrdersRejected = this.ordersOriginal.filter((order) =>
        this.isRejected(order)
      ).length;
      this.numberOrdersPending = this.ordersOriginal.filter((order) =>
        this.isPending(order)
      ).length;
      this.ordersOriginal = this.ordersOriginal.map((order) => {
        if (order.rejectionFlag == '')
          return {
            ...order,
          };
        let rejectionsFlags = order.rejectionFlag.split(',');

        let rejectionFlag = '';
        rejectionsFlags.forEach((flag) => {
          rejectionFlag +=
            this.rejectReasons.find((reason) => reason.id.toString() == flag)
              ?.description + ', ';
        });
        rejectionFlag = rejectionFlag.slice(0, -2);

        order.rejectionFlag = rejectionFlag;
        return {
          ...order,
        };
      });
    });
  }

  getNumberOrders() {
    this.numberOrdersDelivered = this.orders.filter(
      (order) => order.status >= 6 && order.rejectionFlag == ''
    ).length;
    this.numberOrdersRejected = this.orders.filter(
      (order) => order.rejectionFlag != ''
    ).length;
  }

  onFiltrar(event) {
    this.orders = this.ordersOriginal.filter(
      (order) =>
        (order.workName
          .toLowerCase()
          .includes(event.numeroFiltar.toLowerCase()) ||
          order.orderNumber.toString().includes(event.numeroFiltar)) &&
        (event.status == 'Todos' ||
          (event.status == 'Rechazado' && this.isRejected(order)) ||
          (event.status == 'Pendiente' && this.isPending(order)) ||
          (event.status == 'Entregado' && this.isDelivered(order))) &&
        (event.fechaDesde == null ||
          !moment(event.fechaHasta).isBefore(
            moment(order.orderDate, 'DD/MM/YYYY')
          )) &&
        (event.fechaHasta == null ||
          !moment(event.fechaDesde).isAfter(
            moment(order.orderDate, 'DD/MM/YYYY')
          ))
    );
    //this.getNumberOrders();
  }

  toAmOrPm(time: string) {
    const timeArray = time.split(':');
    const hour = parseInt(timeArray[0]);
    const minute = timeArray[1];
    if (hour >= 12) {
      if (hour > 12) {
        return `${hour - 12}:${minute} PM.`;
      }
      return `${hour}:${minute} PM.`;
    } else {
      return `${hour}:${minute} AM.`;
    }
  }

  isRejected(order: Order) {
    return order.rejectionFlag != '';
  }

  isDelivered(order: Order) {
    return (
      (order.status >= 6 ||
        order.status == 0 ||
        order.isSignature ||
        order.isNotSigned) &&
      order.rejectionFlag == ''
    );
  }

  isPending(order: Order) {
    return (
      order.status < 6 &&
      order.status != 0 &&
      order.rejectionFlag == '' &&
      !order.isSignature &&
      !order.isNotSigned
    );
  }

  getReturnReasons() {
    this.orderService.getReturnReasons().subscribe((data) => {
      this.returnReasons = data;
    });
  }

  getReturnReasonDescription(id: number) {
    debugger
    return this.returnReasons.find((reason) => reason.id == id)?.description;
  }

  ConfirmarModificacion(order){
    this.ordersSelected=order;
    this.titleModalUpdate="¿Estas seguro de que este pedido esta pendiente?"
    this.dialogRef = this.dialog.open(this.modalModificar);
  }

  GenerarQR(order){

    this.orderService.setRegenerateQr(
      order.tkt.toString(), 
      order.orderNumber.toString()
    ).subscribe((data) => {
      
      if (data.Estado=="Insertado") {
        const orderNumber = order.tkt; // Asegúrate de que "order" esté disponible en el componente
        const paso = 3;
        this.router.navigate(['/PedidosPendientes/Detalle'], {
            queryParams: { tkt: orderNumber, paso: paso }
        });
      }else{
        this.alertService.error('Error al generar QR.');
      }
      
    });

  }

  closeModal() {
    this.dialogRef.close();

    if (this.isConfirUpdate) {
      window.location.reload();
    }
  }

  ModificarPedido(){
    debugger
    this.orderService.setPendingOrder(
      this.ordersSelected.tkt.toString(), 
      this.ordersSelected.orderNumber.toString()
    ).subscribe((data) => {
      
      if (data.Estado=="Insertado") {
        this.titleModalUpdate="Pedido actualizado a pendiente"
        this.isConfirUpdate=true;
        
      }else{
        this.titleModalUpdate="Error al mover el pedido a pendiente"
        this.isConfirUpdate=true;
      }
      this.dialogRef = this.dialog.open(this.modalModificar);
      
    });
  }

}
